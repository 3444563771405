@import '../../../../scss/theme-bootstrap';

.footer-backtotop {
  #{$rdirection}: 20px;
  position: fixed;
  z-index: 1;
  bottom: 160px;
  top: auto;
  border-radius: 50%;
  width: 53px;
  height: 53px;
  text-align: center;
  background-color: transparent;
  @include breakpoint($landscape-up) {
    #{$ldirection}: auto;
    #{$rdirection}: 44px;
    bottom: 180px;
    width: 60px;
    height: 60px;
  }
  &__anchor {
    text-align: center;
    text-decoration: none;
  }
  &__text {
    font-size: 11px;
    letter-spacing: 0.125em;
    color: $color-black;
    @include breakpoint($landscape-up) {
      font-size: 12px;
    }
  }
  a:hover {
    text-decoration: none;
  }
  .icon--icon-flask {
    margin: 0 auto;
    fill: $color-black;
  }
}
